@import '../util.scss';

.badge {
  padding: 2px 2px;
  line-height: normal;
  font-size: $text-xs;
  font-weight: 600;
  border-radius: $rounded;
  position: relative;
  display: inline-block;
  text-align: center;
  user-select: none;

  &.rounded {
    border-radius: 9999px;
  }

  &.blue {
    color: $white;
    background-color: $blue-500;
    border: 1px solid $blue-600;
  }

  &.red {
    color: $white;
    background-color: $red-500;
    border: 1px solid $red-600;
  }

  &.green {
    color: $white;
    background-color: $green-500;
    border: 1px solid $green-600;
  }

  &.orange {
    color: $white;
    background-color: $orange-500;
    border: 1px solid $orange-600;
  }

  &.yellow {
    color: $white;
    background-color: $yellow-400;
    border: 1px solid $yellow-500;
  }

  &.white {
    color: $black;
    background-color: $white;
    border: 1px solid $gray-200;
  }

  &.gray {
    color: $black;
    background-color: $gray-200;
    border: 1px solid $gray-400;
  }
}